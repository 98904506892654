import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./Rating.scss";
import { apiUrl } from '../../utils';
// /rating?Token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJSZWYiOjI3LCJUeXBlIjoiUmF0aW5nIiwiaWF0IjoxNjEwMDM1Mjc5LCJleHAiOjE2MTA2NDAwNzl9.jj6qG6WFTsvxGAbRkh-uphkXy4GKMROC1a0Aju25Jx4&Plumber=Pa

function StarIcon({ fill, stroke }: { fill: string, stroke: string }) {
  return (
    <svg
      className="single-star"
      fill={fill}
      stroke={stroke}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
      ></path>
    </svg>
  );
}

function RatingIcon({
  index,
  rating,
  hoverRating,
  onMouseEnter,
  onMouseLeave,
  onSaveRating,
}: any) {

  const fill = React.useMemo(() => {
    if (hoverRating >= index) {
      return "#f5be09";
    } else if (!hoverRating && rating >= index) {
      return "#f5be09";
    }
    return "#aaa";
  }, [rating, hoverRating, index]);

  return (
    <div
      className="cursor-pointer"
      onMouseEnter={() => onMouseEnter(index)}
      onMouseLeave={() => onMouseLeave()}
      onClick={() => onSaveRating(index)}
    >
      <StarIcon fill={fill} stroke='none' />
    </div>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Rating = () => {
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [submitMessage, setMessage] = useState('');
  const query = useQuery();
  const Plumber = query.get('Plumber') || '<missing>';
  const Token = query.get('Token') || '<missing>';

  const onMouseEnter = (index: number) => {
    setHoverRating(index);
  };
  const onMouseLeave = () => {
    setHoverRating(0);
  };
  const onSaveRating = (index: number) => {
    setRating(index);
  };

  const submitClicked = async (e: any) => {
    e.preventDefault();

    // const testToken='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJSZWYiOjI3LCJUeXBlIjoiUmF0aW5nIiwiaWF0IjoxNjEwMDM1Mjc5LCJleHAiOjE2MTA2NDAwNzl9.jj6qG6WFTsvxGAbRkh-uphkXy4GKMROC1a0Aju25Jx4';

    const url = apiUrl+'/api/job/rate';
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify({ Token: Token, Rating: rating })
    };

    try {
      const response = await fetch(url, options);
      const bodyJson = await response.json();
      if (bodyJson.error) {
        throw(bodyJson.error);
      }
      setMessage('Your feedback has been submitted - Thank you.');
    } catch (error) {
      // TODO: If there was an error show the message but leave the submit button
      setMessage('There was a system error submitting your feedback: '+error);
    }
  }

  const comments = [
    "Click star to rate",
    "Bad service",
    "Had some problems",
    "Mostly satisfactory",
    "Very good",
    "Excellent!",
  ];

  return (
    <div className="main-content__below-fold rating-container">
      <h1 className="rating-container__title">Customer Rating</h1>
      <p className="rating-container__subtitle">
        We would love to hear from you!
      </p>
      <p className="rating-container__subtitle">
        How would you rate the plumbing services offered by {Plumber}?
      </p>
      <div className="rating-container__star-box">
        {[1, 2, 3, 4, 5].map((index, star) => {
          return (
            <RatingIcon
              key={star}
              index={index}
              rating={rating}
              hoverRating={hoverRating}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onSaveRating={onSaveRating}
            />
          );
        })}
      </div>
      <p className="rating-container__rating">
        {comments[hoverRating || rating]}
      </p>
      { !submitMessage
        ? <button
          className={`rating-container__submit${rating > 0 ? '' : ' disabled'}`}
          onClick={submitClicked}
        >
          Submit Rating
        </button>
        : <p className="rating-container__message">{submitMessage}</p>
      }
    </div>
  );
};

export { Rating };

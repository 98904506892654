type ReactStage = 'development' | 'production' | 'staging';
const stage:ReactStage = process.env.REACT_APP_STAGE as ReactStage || 'development';
// const MainUrl = {
//   'development': 'http://localhost:5000',
//   'staging': 'https://gas247.appzuka.com',
//   'production': 'https://gas-247.co.uk'
// }[stage];
const apiUrl = {
  'development': 'http://localhost:5000',
  'staging': 'https://gas247.appzuka.com',
  'production': 'https://api.gas-247.co.uk'
}[stage];

export {
    // MainUrl,
    apiUrl
}
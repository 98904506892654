import './Home.scss';
import { Link, useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { Services } from '../Services'
import logos from '../../img/Logos.png';

declare global {
  interface Window {
    gtag_report_conversion: () => boolean;
  }
}

const ScrollArrow = () => {
  return(<svg className="scroll-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 226.42 67.46"><polyline points="222.44 9.17 113.21 56.56 3.98 9.17"/></svg>);
}

interface HomeProps {
  location: string;
  service: JSX.Element;
  image: string;
  opacity: string;
}

const Banner = ({
  location, service, image, opacity
}:HomeProps) => {

  // Make the above fold content fill the mobile screen
  // We cannot just use 100vh for this due to the way mobile
  // devices add toolbars to browsers and resize the screen. 
  // See https://medium.com/@susiekim9/how-to-compensate-for-the-ios-viewport-unit-bug-46e78d54af0d
  useEffect(() => {

    const handler = () => {
      const aboveFoldEl = document.getElementById('above-fold');
      if ((window.innerWidth <= 480) && (aboveFoldEl)) {
        if (window.scrollY === 0) {
          aboveFoldEl.style.minHeight = `${window.innerHeight-50}px`;
          if (window.innerHeight/window.innerWidth > 1.65) {
            aboveFoldEl.classList.add('medium')
          } else {
            aboveFoldEl.classList.remove('medium')
          }
          if (window.innerHeight/window.innerWidth > 1.8) {
            aboveFoldEl.classList.add('long')
          } else {
            aboveFoldEl.classList.remove('long')
          }
        }

      }
    };

    window.addEventListener("resize", handler);
    handler();

    return(() => {
      window.removeEventListener("resize", handler);
    })

  }, []);



  return(
  <div className="main-content__above-fold banner-content full" id="above-fold">

    <div className="banner-content__top-items">
    <p className="banner-content__content locations" dangerouslySetInnerHTML={{__html: location}}></p>

  <div className='banner-content__image-block'>
    <img src={image} alt='Plumber' />
    <div className={`image-mask ${opacity}`}></div>
    <div className="title">
      <h1><span>24 hours / 7 Days a Week</span></h1>
      <h1 className="main-title">{service}</h1>
      <h1><span>Plumbers available</span> NOW</h1>
      <a href="tel:+44 7882 958669" className="title-phone" onClick={() => window.gtag_report_conversion()}>
        <p>07882 958669</p>
        <p><span>CLICK HERE</span> to call <span>NOW</span></p>
      </a>
    </div>
  </div>
    </div>
    <div className="banner-content__bottom-items">

  <p className="banner-content__content">
    Call us NOW for a quick response and reliable gas/plumbing service.
  </p>

  <p className="banner-content__content optional-medium">
    All Makes & Models
  </p>

  <p className="banner-content__content optional-long">
  Fast Response, Efficient Service, Local Qualified Professionals
  </p>

  <p className="banner-content__content">
    Free Quotation - No Obligation
  </p>

  <img className="banner-content__logos" src={logos} alt='Gas247 logo'/>
  <p className="banner-content__content terms">
    By proceeding you agree to our <Link to='/terms'>Terms&nbsp;and&nbsp;Conditions</Link> and <Link to="/privacy-policy">Privacy Policy</Link>
  </p>
  <ScrollArrow />
  </div>

</div>)
}

const HomeAdditional = () => {

  return(<>
    <Services />
  </>);
}

const findLocation = (s:string) => {

  interface Locations {
    "newcastle":string;
    "sunderland":string;
  }


  const locations:Locations = {
    "newcastle": "Covering Newcastle, Tyne &amp; Wear, County Durham&nbsp;and&nbsp;Northumberland",
    "sunderland": "Covering Sunderland, Newcastle, Tyne &amp; Wear&nbsp;and&nbsp;County Durham",
  }
  const found = Object.keys(locations).find(k => k === s) as keyof Locations | undefined;
  
  return found
  ? locations[found]
  : "Covering Tyne &amp; Wear, County Durham, Northumberland&nbsp;and&nbsp;Cleveland";

}

const findService = (s:string) => {

  interface Services {
    "boiler":JSX.Element;
  }


  const services:Services = {
    "boiler":<>EMERGENCY<br />BOILER REPAIR</>,
  }
  const found = Object.keys(services).find(k => k === s) as keyof Services | undefined;
  
  return found
  ? services[found]
  : <>EMERGENCY<br />SERVICE</>;

}

const findTitle = (s:string) => {

  interface Services {
    "boiler":string;
  }

  const services:Services = {
    "boiler":"Emergency Boiler Repair",
  }
  const found = Object.keys(services).find(k => k === s) as keyof Services | undefined;
  
  return found
  ? services[found]
  : "Emergency Plumbing Service";

}

const findImage = (s:string) => {

  interface Images {
    "boiler": string;
  }


  const images:Images = {
    "boiler": '/img/home-boiler.jpg',
  }
  const found = Object.keys(images).find(k => k === s) as keyof Images | undefined;
  
  return found
  ? images[found]
  : '/img/home-plumbing.jpg';

}

const Register = () => {
  return(<Link className='home-register' to='/register'>
    <h2>Plumbers</h2>
    <p>Click here to register with us.  Once we have verified your certifications you can start receiving job alerts in your area.</p>
    <button className="home-register__button">Plumber Register</button>
  </Link>)
}

const Home = () => {
  let {service, location}:{service:string, location:string} = useParams();


  return(
	  <>
      <Helmet>
        <title>{findTitle(service)}</title>
      </Helmet>
      <Banner
      location={findLocation(location)}
      service={findService(service)}
      image={findImage(service)}
      opacity={service==='boiler'?'high':'low'}
      />
              <div className='main-content__below-fold'>
                <HomeAdditional />
                {/* <Register /> */}
              </div>
	</>
  )
}

export {
  Home
}
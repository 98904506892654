import React, { useState } from "react";
import { apiUrl } from '../../utils';
import "./Password.scss";

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [submitMessage, setMessage] = useState('');

  const submitClicked = async (e: any) => {
    e.preventDefault();

    const url = apiUrl + '/api/users/forgotpass';
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify({ EmailId: email })
    };

    try {
      const response = await fetch(url, options);
      const bodyJson = await response.json();
      console.log(bodyJson);
      if (bodyJson.error) {
        throw(bodyJson.error);
      }
      setMessage('Please check your eMail for a link to choose a new password');
    } catch (error) {
      // TODO: If there was an error show the message but leave the submit button
      setMessage('There was a system error submitting your request: '+error);
    }
  }

  const checkemail = (email:string) => {
    return !!email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
  }

  return (
    <div className="main-content__below-fold password-container">
      <h1 className="password-container__title">Request Password Reset</h1>
      <p className="password-container__subtitle">
        Enter your email below to request a password reset link. The link will be sent to the email address you enter if it matches the address of an account on record.
      </p>
      <form onSubmit={submitClicked}>
        <div className="input-wrapper">
          <label htmlFor='email'>email:</label>
          <input type="text" id='email' value={email} onChange={event => {
            checkemail(event.target.value);
            setEmail(event.target.value)
            }} />
        </div>

        { !submitMessage
        ? <input className={`password-container__submit${checkemail(email) ? '' : ' disabled'}`} type="submit" value="Send Link" />
        : <p className="password-container__message">{submitMessage}</p>
      }
      </form>
    </div>
  );
};

export { ResetPassword };

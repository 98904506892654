import { useEffect, useState } from 'react';


import './Services.scss';

const ServiceImage = ({imageList, activeImage} : {imageList: string[], activeImage: number}) => {

  return(
    <div className='services__image'>
      {imageList.map((image, i) => {
        return(<img key={i} src={`/img/${image}`} style={{opacity: i===activeImage ? 1 : 0}} alt='Plumber' />);
      })}
    </div>
  )
};


const Service = ({service, serviceIndex, activeImage} : {service: any, serviceIndex: number, activeImage: number}) => {

  return(
    <div className='services__tile'>
      <h2>{service.title}</h2>
      <ServiceImage imageList={service.images} activeImage={activeImage}/>
      <ul>
        {service.items.map((si:string, i:number) => {
          return(<li key={i}><p>{si}</p></li>)
        })}
      </ul>
    </div>
  )
};

const serviceList = [
  {
    title: 'Emergency Call Out',
    images: ['set-1/photo-2.jpg', 'set-1/photo-3.jpg'],
    items: [
      'Gas and water leaks',
      'Available 24 hours. 7 days a week.',
      'Covering whole north east of England.',
      'Reliable & quick response.'
    ]
  },
  {
    title: 'Gas Installation & Repairs',
    images: ['set-2/photo-4.jpg', 'set-2/photo-5.jpg'],
    items: [
      'Gas Safe Registered Plumbers',
      'All boilers & heating systems fitted.',
      'Yearly gas safety checks',
      'All boilers and gas appliances repaired.'
    ]
  },
  {
    title: 'Plumbing Repairs',
    images: ['set-3/photo-6.jpg', 'set-3/photo-7.jpg'],
    items: [
      'Burst & Leaking Pipes',
      'Repair showers & taps... etc',
      'Leaking sink & basin fittings.',
      'Radiator leaks & blocked pipes.'
    ]
  },
  {
    title: 'Plumbing Installations',
    images: ['set-4/photo-8.jpg', 'set-4/photo-9.jpg', 'set-4/photo-10.jpg', 'set-4/photo-11.jpg'],
    items: [
      'From fitting a tap ... toilet ... sink ... radiator... to full bathroom suite.',
      'Refurbishments.',
      'Conversions & new builds.'
    ]
  }
];

const Services = () => {
  const [currentTile, setCurrentTile] = useState(0);
  const [currentImage, setCurrentImage] = useState(new Array(serviceList.length).fill(0));
  const [initialDelay, setInitialDelay] = useState(1);

  useEffect(() => {
    const switchTimer = setInterval(() => {
      if (initialDelay > 0) {
        setInitialDelay(initialDelay - 1);
        return;
      }
      setCurrentTile((currentTile+1) % serviceList.length);
      const newImageArray = currentImage;
      newImageArray[currentTile] = (newImageArray[currentTile]+1) % serviceList[currentTile].images.length;
      setCurrentImage(newImageArray)
    }, 1500);
    return(() => clearInterval(switchTimer));
  });

  return(
    <div className="main-content__below-fold service-container">
      <h1 className="service-container__title">Services</h1>
      <p className="service-container__subtitle">Established since 2016, Gas 24/7 Limited are available 24/7 with the below services:</p>
      <div className="services">
        {serviceList.map((s, i) => {
          return(
            <Service key={i} service={s} serviceIndex={i} activeImage={currentImage[i]}/>
          )
        })}
      </div>
	</div>
  )
}

export {
  Services
}
import { Link } from "react-router-dom";
import visa from '../../img/IMAGES01.01.Visa.png';
import mastercard from '../../img/IMAGES01.02.Mastercard.jpg';
import './Footer.scss';

const MailLink = () => {
  return(<a href='info@gas-247.co.uk'>info@gas-247.co.uk</a>);
}

const AdminLink = () => {
  return(<a target="_blank" rel="noreferrer" className="admin-link" href='https://admin.gas-247.co.uk'>admin</a>);
}

const HomeLink = () => {
  return(<Link to='/'>Home</Link>);
}

const TermsLink = () => {
  return(<Link to='/terms'>Terms &amp; Conditions</Link>);
}

const PrivacyLink = () => {
  return(<Link to='/privacy-policy'>Privacy Policy</Link>);
}

const CookieLink = () => {
  return(<Link to='/cookie-policy'>Cookie Policy</Link>);
}

const Footer = () => {
	return(
		<footer className="footer-area">
			<div className="footer-area__content">
				<h2 className="footer-area__title">Contact Us</h2>
				<div className="footer-area__footer-address">
					<p>11 Studley Terrace, Newcastle upon Tyne, NE4 5PA.</p>
					<p>Email: <a href='mailto:info@gas-247.co.uk'>info@gas-247.co.uk</a></p>
					<p>Gas 24/7 Limited is a company registered in England. Company number <span>10240528</span></p>
				</div>
				<p className="footer-area__phone">Phone: <span>07882 958669</span></p>
				<div className="footer-area__payment-logos">
					<img src={visa} alt='visa logo'/>
					<img src={mastercard} alt='mastercard logo'/>
				</div>	
				<div className="footer-area__links">
          <div className="footer-area__page-links">
            <HomeLink />
            <TermsLink />
            <PrivacyLink />
            <CookieLink />
          </div>
          <div className="footer-area__mail-links">
					  {/* <AdminLink /> */}
					  <MailLink />
          </div>
				</div>	
			</div>	
		</footer>
	)
}

export {
  Footer
}
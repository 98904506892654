import './Register.scss';
import { Helmet } from 'react-helmet';
import playStoreLogo from '../../img/en_badge_web_generic.png';
import appStoreLogo from '../../img/download-on-the-app-store-badge.png';

const Register = () => {
    return(
      <div className="main-content__below-fold register-container">
        <Helmet>
          <title>Gas24/7 | Register</title>
        </Helmet>
        <h1 className="register-container__title">Register</h1>
        <p className="register-container__subtitle">Register today and receive work from us, as soon as you are approved.</p>
        <ol>
          <li><p>Download the Gas24/7 App for iPhone or Android</p></li>
          <li><p>Register your details</p></li>
          <li><p>Start receiving job alerts</p></li>
        </ol>
        <p>Download the app at the following links:</p>
        <div className="register-container__links">
          <a href='https://apps.apple.com/app/id1444967125' target='_blank' rel="noreferrer"><img className="register-container__logo ios-logo" src={appStoreLogo} alt='Apple app store logo' /></a>
          <a href='https://play.google.com/store/apps/details?id=com.gas247appv2' target='_blank' rel="noreferrer"><img className="register-container__logo play-logo" src={playStoreLogo} alt='Google Play store logo' /></a>
        </div>
        <p>To complete the registration process and get verified you will need the information listed below.  Scroll down to view a video showing the registration process.</p>
        <ol>
          <li><p>Your Name, Address, eMail and mobile contact details</p></li>
          <li><p>The services you offer: Plumbing Repairs, Installations, Gas Repairs</p></li>
          <li><p>Digital copies of your documents for verification:</p></li>
          <ul>
            <li><p>Driving Licence</p></li>
            <li><p>Liability Insurance</p></li>
            <li><p>Gas Safe Card (if you offer Gas services)</p></li>
          </ul>
        </ol>
        <p>All of these details can be entered from your phone when you register from the app. Documents can be uploaded from your photo gallery.</p>
        <p>Once you have registered you can go online and you will receive alerts of new jobs in your area.  Just click 'Accept' within the app to take the job.</p>
        <p>Below is a video taking you through the entire process of registration through to accepting a job.</p>
        <div className="register-container__video">
          <iframe title="registration-video" width="560" height="315" src="https://www.youtube.com/embed/51TogMDQOgI" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
    </div>
    )
}

export {
  Register
}
import {
  Link
} from "react-router-dom";
import logo from '../../img/logo.png';
import './Header.scss';

declare global {
  interface Window {
    gtag_report_conversion: () => boolean;
  }
}

const PhoneIcon = () => {
  return(<svg className="phone-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.67 120.52">
<path d="M88.11,120.52a84.6,84.6,0,0,1-11.55-.8,87.5,87.5,0,0,1-50.41-25C6.76,75.23,1.36,56,.24,43.24-1,28.37,3,17.72,5.35,15.32l11.9-11.9A11.85,11.85,0,0,1,25.55,0a13.81,13.81,0,0,1,8.08,2.66,1.48,1.48,0,0,1,.61.55l15.59,20a11.8,11.8,0,0,1-.18,16.43L40,49.32c-3,3-7.24,3.27-9,3.27h0a9.83,9.83,0,0,1-1.2-.06,3.93,3.93,0,0,1,.37-7.84h.29a3.83,3.83,0,0,0,.47,0,5.32,5.32,0,0,0,3.43-1l9.7-9.7a3.88,3.88,0,0,0,.14-5.24,1.62,1.62,0,0,1-.42-.44L28.2,8.47a3.69,3.69,0,0,0-2.55-.94A3.74,3.74,0,0,0,23,8.62L11.1,20.47C10.15,21.69,7,30.32,8.21,42.76c1.07,11.29,6,28.45,23.64,46.06a79.71,79.71,0,0,0,45,22.69,80.49,80.49,0,0,0,11.42.85c7.72,0,12.38-1.24,13.31-1.68l12.26-12.26a3.74,3.74,0,0,0,1.08-2.7,4,4,0,0,0-.94-2.55L93.79,78.39a2.15,2.15,0,0,1-.64-.47,3.84,3.84,0,0,0-5.5,0L78,87.62A4.09,4.09,0,0,0,77,91.44,4.07,4.07,0,0,1,76.24,95a3.88,3.88,0,0,1-3,1.43,3.59,3.59,0,0,1-.47,0c-3.2-.37-19.48-2.85-31.93-15.3A50.6,50.6,0,0,1,29.74,63.94a3.78,3.78,0,0,1,0-2.95,3.84,3.84,0,0,1,2.16-2.1,3.75,3.75,0,0,1,1.4-.27A3.93,3.93,0,0,1,37,61.11a43,43,0,0,0,9.37,14.51c7.48,7.55,17,10.88,22.84,12.28a11.85,11.85,0,0,1,3.16-5.69l9.7-9.69a11.79,11.79,0,0,1,8.3-3.39,11.51,11.51,0,0,1,8,3.2l20.24,14.81a2.18,2.18,0,0,1,.64.48,11.83,11.83,0,0,1,3.42,8.3,11.62,11.62,0,0,1-3.42,8.3l-12.5,12.5C104.44,119,97.13,120.52,88.11,120.52Z"/></svg>);
}

const HandIcon = () => {
  return(<svg className="hand-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.71 95.13">
  <path d="M78.47,95.13a4.13,4.13,0,0,1-2.59-.91l-6.56-5.89c-12.25,0-19.52-.1-21.59-.3-3.78-.32-32.31-11.55-36-15.21a10.5,10.5,0,0,1-3.09-9.44,11.12,11.12,0,0,1,5.56-8,8.85,8.85,0,0,1,4.05-.93c3,0,6,1.27,8.39,2.29l.25.1a14.14,14.14,0,0,0,1.87.79c.24,0,.63-.36,1-1.12,1.18-2.53.77-6.76-2.34-9.86L4.56,23.72A17,17,0,0,1,.17,14.54,14,14,0,0,1,4.06,2.31,7.55,7.55,0,0,1,9.37,0h.1c4.24.12,7.69,3.71,10.22,6.33.39.4.8.81,1.15,1.25L41.51,28.25c7.65-4.29,20.43-11,27-11.84a13.8,13.8,0,0,1,1.68-.11c8.54,0,12.92,8.58,17.56,17.67.3.61.62,1.22.94,1.84s.66,1.27,1,1.87A40.89,40.89,0,0,1,94.33,59l7.39,8.25a3.9,3.9,0,0,1-.1,5.29L87.79,87.09a3.91,3.91,0,0,1-5.47.18,3.9,3.9,0,0,1-.21-5.5L93.44,69.89l-6.13-6.83a3.94,3.94,0,0,1-1-3.1v0a34.92,34.92,0,0,0-3.75-18.55c-.28-.51-.54-1-.81-1.56-.39-.77-.76-1.49-1.16-2.18-4.19-8.21-7.19-13.54-10.87-13.54l-.53,0c-5.38.72-20.29,8.61-26.66,12.32a3.84,3.84,0,0,1-1.95.54,3.91,3.91,0,0,1-2.77-1.16L15,12.92a15.74,15.74,0,0,1-1.26-1.37C11,8.68,9.81,7.89,9.3,7.69a6,6,0,0,0-1.63,5.42,10,10,0,0,0,2.2,4.81l22.9,22.89c6,6,6.18,13.88,3.87,18.72-1.71,3.53-4.56,5.56-7.83,5.56a7.33,7.33,0,0,1-1-.07,13.43,13.43,0,0,1-4.21-1.42l-.35-.15c-1.51-.65-3.8-1.63-5.16-1.63a1.32,1.32,0,0,0-.46.06,3.36,3.36,0,0,0-1.44,2.3,3,3,0,0,0,.91,2.63c2.45,2.26,28.33,12.63,31.13,12.9C50.84,79.9,64,80,70.57,80a3.64,3.64,0,0,1,2.72,1.13L80.82,88a3.85,3.85,0,0,1,1.27,2.68,3.8,3.8,0,0,1-.73,2.52A2.78,2.78,0,0,1,78.47,95.13Z"/></svg>);
}


const Header = () => {
	return(
    <div className='app-header'>
    <div className='app-header__content'>
      <Link className="app-header__logo" to='/'>
        <img className="logo" src={logo} alt='Gas247 logo'/>
      </Link>
      <a href="tel:+44 7882 958669" onClick={() => window.gtag_report_conversion()} className="app-header__mobile-phone-cta">
        <PhoneIcon />
        <div className="phone-now">
          <h2>Call Now</h2>
          <HandIcon />
        </div>
      </a>
      <a href="tel:+44 7882 958669" className="app-header__desktop-phone-cta">
        <PhoneIcon />
        <p className="phone-now">
          <span>Call Now : </span>07882 958669
        </p>
      </a>
    </div>
    <div className='app-header__menu'>
      <ul className='app-header__menu-left'>
        <li><Link to='/' >Home</Link></li>
        <li><Link to='/services' >Services</Link></li>
      </ul>
      {/* <ul className='app-header__menu-right'>
        <li><Link to='/register' ><span>Plumber </span>Register</Link></li>
      </ul> */}
    </div>
  </div>
	);
};

export {
  Header
}
import { useState, useEffect } from 'react';
import { Home } from './Pages/Home';
import { Terms } from './Pages/Policies/terms';
import { Privacy } from './Pages/Policies/privacy';
import { Cookies } from './Pages/Policies/cookies';
import { Register } from './Pages/Register';
import { Rating } from './Pages/Rating';
import { ResetPassword } from './Pages/Password/reset';
import { NewPassword } from './Pages/Password/new';
import { Services } from './Pages/Services';
import { NotFound } from './Pages/NotFound';
import { Footer } from './Components/Footer';
import { Header } from './Components/Header';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Link
} from "react-router-dom";
import './App.scss';


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const CookieWarning = ({cancelWarning}:{cancelWarning:any}) => {

  const acceptCookies = (e:any) => {
    e.preventDefault();
    console.log('Cookies accepted');
    window.localStorage.setItem('cookies', 'accepted');
    cancelWarning(false);
  }
  return(
    <div id="cookie-warning">
      <p>This website uses cookies. To learn more read our cookie and content policy. <Link to='/cookie-policy'>View Policy</Link></p>
      <button onClick={acceptCookies}
      >Accept & Proceed</button>
    </div>
  )
}


function App() {
  const [showWarning, accept] = useState(window.localStorage.getItem('cookies') !== 'accepted')

  return(
    <Router>
      <div className='appframe'>
        { showWarning && <CookieWarning cancelWarning={accept} /> }
        <ScrollToTop />
        <Header />
        <div className='main-content'>
          <div className='main-content__left'></div>
          <div className='main-content__body'>
            <Switch>
              <Route path="/" exact>
                <Helmet>
                  <title>Gas24/7 | Emergency Plumber</title>
                </Helmet>
                <Home />
              </Route>
              <Route path="/terms">
                <Terms />
              </Route>
              <Route path="/services">
                <Helmet>
                  <title>Gas24/7 | Services</title>
                </Helmet>
                <Services />
              </Route>
              <Route path="/privacy-policy">
                <Privacy />
              </Route>
              <Route path="/cookie-policy">
                <Cookies />
              </Route>
              <Route path="/register">
                <Register />
              </Route>
              <Route path="/rating">
                <Rating />
              </Route>
              <Route path="/resetrequest">
                <ResetPassword />
              </Route> 
              <Route path="/resetpassword">
                <NewPassword />
              </Route>
              <Route path="/:service/:location">
                <Home />
              </Route>
              <Route path="/:service">
                <Home />
              </Route>
              <Route path="/">
                <NotFound />
              </Route>
            </Switch>

          </div>
          <div className='main-content__right'></div>
        </div>
        <Footer />
      </div>
    </Router>
  )
}

export {
  App
};

import { Link } from "react-router-dom";
import './NotFound.scss';

const NotFound = () => {
  return(
    <div className="main-content__below-fold notfound-container">
      <h1 className="notfound-container__title">Page Not Found</h1>
      <p className="notfound-container__subtitle">Please click on a link from the menu bar above or the footer.</p>
      <p className="notfound-container__home"><Link to='/'>Click here to return to the Home page.</Link></p>
	</div>
  )
}

export {
  NotFound
}
import { Helmet } from 'react-helmet';
import "./Terms.scss";

const Terms = () => {
  return (
    <>
      <div className="main-content__below-fold terms-container">
        <Helmet>
          <title>Gas24/7 | Terms</title>
        </Helmet>
        <h2>Terms &amp; Conditions</h2>

        <h4>Acceptance Statement</h4>
        <p>Use of our services constitutes acceptance of the Terms and Conditions.</p>
        <p>By accessing and using this service, you accept and agree to be bound by the terms and provision of this agreement.</p>
        <p>In addition, when using these particular services, you shall be subject to any posted guidelines or rules applicable to such services. Any participation in this service will constitute acceptance of this agreement. If you do not agree to abide by the above, please do not use this service.</p>

        <h4>Liabilty Disclaimer</h4>
        <p>This site and its components are offered for informational purposes only; this site shall not be responsible or liable for the accuracy, usefulness or availability of any information transmitted or made available via the site, and shall not be responsible or liable for any error or omissions in that information.</p>
          <p>We will not be responsible for any damages or loss arising from the use of our service, website or mobile app.</p>

        <h4>Intellectual Property</h4>
        <p>
          This Site and its original content, features, and functionality are
          owned by Gas 24/7 Limited and are protected by international
          copyright, trademark, patent, trade secret, and other intellectual
          property or proprietary rights laws.
        </p>

        <h4>Pricing Plan</h4>
        <h5>Emergency Rates</h5>
        <div className="terms-container__payment-plan">
          <p>Monday to Sunday (excluding Bank Holidays)</p>
          <p>8am to 4pm.</p>
          <p>Emergency Callout Booking Fee £65.00<span>Call out charge is £85.00 for first hour then £42.50 every hour thereafter.</span></p>
        </div>
        <div className="terms-container__payment-plan">
          <p>Monday to Sunday (excluding Bank Holidays)</p>
          <p>4pm to 8am.</p>
          <p>Emergency Callout Booking Fee £75.00<span>Call out charge is £95.00 for first hour then £47.50 every hour thereafter.</span></p>
        </div>

        <h5>Non-Emergency Rates</h5>
        <div className="terms-container__payment-plan">
          <p>Non-Emergency Rates are the same as our Emergency Rates above</p>
        </div>
        {/* <div className="terms-container__payment-plan">
          <p>Monday to Friday (excluding Bank Holidays)</p>
          <p>8am to 4pm.</p>
          <p>Callout Booking Fee £25.00<span>Call out charge is £75.00 for first hour then £32.50 every hour thereafter OR at an agreed price/estimate/quote between the engineer and the customer.</span></p>
        </div>
        <div className="terms-container__payment-plan">
          <p>Saturday and Sunday (excluding Bank Holidays)</p>
          <p>8am to 8pm</p>
          <p>Callout Booking Fee £45.00<span>Call out charge is £75.00 for first hour then £32.50 every hour thereafter OR at an agreed price/estimate/quote between the  engineer and the customer.</span></p>
        </div> */}

        <p>Prices are doubled for Bank Holidays.</p>
        <p>Prices include VAT.</p>

        <h4>Clauses</h4>

        <p>You must be 18 years of age or over to use tour website, mobile app and services.</p>
        <p> We will not be responsible for any phone call charges or costs, the caller may incur on a phone call to us.</p>
        <p>Accepted payment method to pay for an emergency rate Emergency Callout Booking Fee is by credit/debit card only.</p>
        <p>Accepted payment method to pay for a non-emergency rate Callout Booking Fee is by credit/debit card or BACS bank transfer only.</p>
        <p>We will not note down, collect or store; the card details and the Card Security Code (CSC) or Card Verification Value (CVV) you provide us.</p>
        <p>We will not be responsible for additional bank charges and/fees you may incur on your purchase for our services.</p>
        <p>Once we have an engineer available to fix your fault. You must pay us the Callout Booking Fee before the engineer is dispatched.</p>
        <p>Once we have given you a confirmation that the engineer has been dispatched to the destination. Your Callout Booking Fee is non-refundable.</p>
        <p>This is because we may have to pay the engineer for inconvenience caused.</p>
        <p>Any incorrect details you provide to us may result in a loss of your Callout Booking Fee. And, you may need to pay another Callout Booking Fee to dispatch another/the same engineer again.</p>
        <p>An ETA will be given to you, on what time, the engineer will arrive at your destination.</p>
        <p>A visit begins as soon as the engineer has arrived, parked and turned off their vehicle's engine at the destination.</p>
        <p>Once the visit begins, the first hour call out charge is chargeable and is non-refundable.</p>
        <p>After the first hour, each additional hour thereafter is chargeable. See Pricing Plan.</p>
        <p>Parking, congestion charges or tolls may be added to the call out charge.</p>
        <p>During and after the visit; you must abide by the Terms &amp; Conditions of the engineer.</p>
        <p>The call out charge does not include parts and materials.</p>
        <p>On completion of the work to repair your fault; whether it be a permanent or temporary fix. You must pay the engineer the total call out charge including any costs incurred for parts and materials used. Accepted payment method must be by an acceptable method for the engineer; i.e. cash, credit/debit card, cheque, BACS bank transfer or PayPal.</p>
        <p>The engineer will own all parts and material, used to repair your fault, until paid in full. If not paid in full; the engineer has the right to remove the parts or material.</p>
        <p>If the total call out charge payment is not received on completion. A £35 administration charge will be levied against you along with a £20 for a recorded letter to be sent to you. An additional £20 will apply for every letter sent thereafter to you. If legal and/or debt management services are needed; then you will be required to pay the costs.</p>
        <p>We will not be responsible for the work or give any guarantee on any work carried out by the engineer supplied to you.</p>
        <p>Any guarantee on any work carried out lies solely with the engineer.</p>
        <p>We will not be responsible and do not accept liability for any accidental damage to your property caused by the engineer.</p>
        <p>We will not be responsible and do not accept liability for any worsening/damage caused by the engineer cancelling the visit or you waiting for the engineer to arrive at the destination.</p>
        <p>A receipt for the work provided by the engineer will be provided by the engineer.</p>
        <p>The Callout Booking Fee will not be shown on the engineer receipt. You will need to contact us to request a separate receipt to be send to you.</p>
        <p>We will only provide estimates and quotes for non-emergency rate work.</p>

        <h4>Cancellation and Refund Policy</h4>
        <p>You are entitled to a full refund of your Callout Booking Fee if:</p>
          <ul>
            <li><p>we are unable to provide you a service.</p></li>
            <li><p>the engineer arrival at the destination exceeds the ETA by one hour.</p></li>
            <li><p>the engineer fails to do the visit at all.</p></li>
          </ul>
        <p>We will not be responsible if the customer cancels before the engineer arrives at the destination.</p>
        <p>If there is a cancellation; before the engineer arrives at the destination; the engineer may receive part of the Callout Booking Fee.</p>

        <h4>Complaints Procedure</h4>
        <p>All complaints on our service must be provided to us in writing; i.e. email or letter.</p>
        <p>You must allow us up to 30 days to reply to your complaint.</p>
        <p>All complaints on the engineer must be dealt with directly with the engineer. We will forward the engineer contact details to you.</p>
        <p>Reviews on our services must not be made public on any social media or sites until a legal outcome/judgement has been made. If you do publicise reviews; this will be deemed, the complaint is in our favour. We can then claim compensation from you, for making the complaint.</p>
        <p>We may terminate the engineer access to the Site, without cause or notice, which may result in the forfeiture and destruction of all information associated with the engineer account.</p>
        <p>All provisions of this Agreement that, by their nature, should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>
        <p>The company reserves the right to change these conditions from time to time as it sees fit and your continued use of the site will signify your acceptance of any adjustment to these terms.</p>
        <p>If there are any changes to our privacy policy, we will announce that these changes have been made on our home page and on other key pages on our site.</p>
        <p>If there are any changes in how we use our site customers' Personally Identifiable Information, notification by email or postal mail will be made to those affected by the change.</p>
        <p>Any changes to our privacy policy will be posted on our site 30 days prior to these changes taking place. You are therefore advised to re-read this statement on a regular basis.</p>

        <p>Where the context admits any reference to:</p>
        <ul>
          <li><p>'We' and 'us' and 'our' refers to Gas 24/7 Limited.</p></li>
          <li><p>'Customer' 'you' and 'your' refers to the person or company who is buying the service from Gas 24/7 Limited.</p></li>
          <li><p>'Caller' refers to the customer or engineer who calls Gas 24/7 Limited on the phone.</p></li>
          <li><p>'Callout Booking Fee' refers to the first instalment payment to Gas 24/7 Limited.</p></li>
          <li><p>'Visit' refers to the point the engineer arrives at the destination.</p></li>
          <li><p>'Confirmation' refers to a written or verbal confirmation given to the Customer.</p></li>
          <li><p>'Engineer' refers to the gas engineer or plumber appointed by Gas24/7 Limited. Engineer is not employed by Gas 24/7 Limited and is self employed.</p></li>
          <li><p>'ETA' refers to the Estimated Time of Arrival for an emergency rate fault.</p></li>
          <li><p>'Destination' refers to:</p></li>
          <ul>
            <li><p>the location of the property where the fault is at.</p></li>  
            <li><p>an alternative location, before the place, where the fault is at; for example, to collect keys.</p></li>  
            <li><p>a 1 mile radius of the destination; if the engineer has contacts the Customer for directions and the Customer no longer requires the fault to be fixed.</p></li>  
          </ul>
          <li><p>'Call out charge' refers to the fee to the engineer. The first hour and every hour thereafter.</p></li>
          <li><p>'Fault' refers to the customer's repair which needs to be fixed.</p></li>
          <li><p>The singular shall include the plural and vice versa</p></li>
        </ul>

          <p>If you have any questions regarding this agreement, please contact us
          at: <a href='mailto:info@gas-247.co.uk'>info@gas-247.co.uk</a></p>
      </div>
    </>
  );
};

export { Terms };

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { apiUrl } from '../../utils';
import "./Password.scss";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const NewPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [submitMessage, setMessage] = useState('');
  const query = useQuery();
  const Token = query.get('Token');

  const checkPassword = (newPassword: string) => {
    return(newPassword.length > 5);
  }

  const submitClicked = async (e: any) => {
    e.preventDefault();
    
    const url = apiUrl + '/api/users/resetpass';
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify({ NewPassword: newPassword, Token: Token })
    };

    try {
      const response = await fetch(url, options);
      const bodyJson = await response.json();
      // console.log(bodyJson);
      if (bodyJson.error) {
        throw(bodyJson.error);
      }
      setMessage('Your password has been changed.');
    } catch (error) {
      // TODO: If there was an error show the message but leave the submit button
      setMessage('There was a system error submitting your request: '+error);
    }
  }

  return (
    <div className="main-content__below-fold password-container">
      <h1 className="password-container__title">Change Password</h1>
      <p className="password-container__subtitle">
        A request was made to change your password.  If you did not make this request you can ignore this email.
      </p>
      <form onSubmit={submitClicked}>
        <div className="input-wrapper">
          <label htmlFor='newpassword'>New Password:</label>
          <input type="text" id='newpassword' value={newPassword} onChange={event => {
            setNewPassword(event.target.value)
            }} />
        </div>

        { !submitMessage
        ? <input className={`password-container__submit${checkPassword(newPassword) ? '' : ' disabled'}`} type="submit" value="Change Password" />
        : <p className="password-container__message">{submitMessage}</p>
      }
      </form>
    </div>
  );
};

export { NewPassword };
